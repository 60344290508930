<template>
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12.9 2.1999H14.1C14.5988 2.1999 15 2.60303 15 3.0999C15 3.59678 14.5988 3.9999 14.1 3.9999H12.9V5.00865L16.7663 7.51365C17.535 8.00865 18 8.86365 18 9.77865V17.7999H21.3C21.7987 17.7999 22.2 17.3987 22.2 16.8999V14.2787C22.2 13.9412 22.0463 13.6899 21.7875 13.4912L19.2 11.8487V9.70365L22.7663 12.0137C23.535 12.5087 24 13.3637 24 14.2787V16.8999C24 18.3924 22.7925 19.5999 21.3 19.5999H2.7C1.209 19.5999 0 18.3924 0 16.8999V14.2787C0 13.3637 0.46425 12.5087 1.23338 12.0137L4.8 9.70365V11.8487L2.211 13.4912C1.95487 13.6899 1.8 13.9412 1.8 14.2787V16.8999C1.8 17.3987 2.20312 17.7999 2.7 17.7999H6V9.77865C6 8.86365 6.465 8.00865 7.23375 7.51365L11.1 5.00865V3.9999H9.9C9.40125 3.9999 9 3.59678 9 3.0999C9 2.60303 9.40125 2.1999 9.9 2.1999H11.1V1.2999C11.1 0.803027 11.5013 0.399902 12 0.399902C12.4988 0.399902 12.9 0.803027 12.9 1.2999V2.1999ZM12 6.5724L8.17875 8.99115C7.95375 9.1899 7.8 9.44115 7.8 9.77865V17.7999H10.2V14.1999C10.2 13.2062 11.0063 12.3999 12 12.3999C12.9938 12.3999 13.8 13.2062 13.8 14.1999V17.7999H16.2V9.77865C16.2 9.44115 16.0463 9.1899 15.7875 8.99115L12 6.5724Z"
    />
  </svg>
</template>
